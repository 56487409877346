<template>
  <div class="container answers">
    <!-- <div v-show="load">
      <div class="load"></div>
      <h1 class="fullscreen-hint text-center">
        Loading... <br />
        正在更新请稍后...
      </h1>
    </div> -->
    <div style="padding-bottom:0">
      <div class="action-btns">
        <div v-if="createNew === '1'">
          <div>
            <el-button style="width:150px" type="success" @click="createAnswers"
              ><i class="fa fa-save"></i> 創 建</el-button
            >
          </div>
        </div>
        <div v-else>
          <div v-show="cannotEdit">
            <el-button type="success" @click="updateScore">
              重新批改所有试卷
            </el-button>
            <el-button
              class="testIdSave"
              @click="cannotEdit = false"
              type="info"
              ><i class="fa fa-edit"></i> 編輯</el-button
            >
          </div>
          <div v-show="!cannotEdit">
            <div>
              <el-button
                style="width:150px"
                type="success"
                @click="updateAnswers"
                ><i class="fa fa-save"></i> 保存</el-button
              >
            </div>
            <div>
              <el-button
                style="width:150px;margin-top:10px;color: red"
                class="testIdSave"
                @click="reloadAnswer(act_info.id)"
                ><i class="fa fa-times"></i> 取消</el-button
              >
            </div>
          </div>
        </div>
      </div>
      <el-breadcrumb
        separator-class="el-icon-arrow-right"
        style="font-size:18px;margin-bottom: 20px"
      >
        <el-breadcrumb-item>
          <a href="/home">Dashboard</a>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          <a href="/tests">Tests</a>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          Answers
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          <span v-if="createNew === '1'">
            Add New Test
          </span>
          <span v-else>
            {{ act_info["full_name"] }} ({{ act_info["title"] }})
          </span>
        </el-breadcrumb-item>
      </el-breadcrumb>
      <h2>
        <span v-if="createNew === '1'">
          Add New Test
        </span>
        <span v-else>
          Edit Test - {{ act_info["full_name"] }} ({{ act_info["title"] }})
        </span>
      </h2>
      <TestForm v-if="act_info" :info="act_info" :cannotEdit="cannotEdit" />
      <hr />
      <h4 class="title-text">
        TEST PASSAGE AND QUESTION
      </h4>
      <SectionTable
        v-if="$route.query.exam_id"
        testType="act"
        :testTypeId="$route.query.exam_id"
      />
    </div>
    <!-- TEST ANSWER -->
    <div class="">
      <hr />
      <div class="title">
        <h4 class="title-text">
          TEST ANSWER
        </h4>
      </div>
      <div v-show="!cannotEdit">
        <div style="margin-bottom:20px">
          <el-alert type="warning" :closable="false" show-icon>
            <ul style="margin-bottom:0">
              <li>單選題中有多個正確答案用括號 <b>(A,B)</b> ;</li>
              <li>括號中的答案用英文狀態下的逗號 <b>,</b> 隔開;</li>
              <li>全部都算對用 <b>(*)</b> 表示。</li>
            </ul>
          </el-alert>
        </div>
        <div class="test_input">
          <div
            class="row input-line"
            v-for="(answer, index) in test_answers_detail"
            :key="index"
            v-show="index !== 'essay'"
          >
            <div class="col-md-1">
              <span class="input-title">
                <b v-html="topic_array[index]"></b>
              </span>
            </div>
            <div class="col-md-11">
              <div class="input-group">
                <input
                  class="form-control"
                  rows="2"
                  v-model="test_answers_detail[index]"
                  @change="setAllAnswer"
                />
              </div>
            </div>
            <!-- <div class="col-md-2">
              <span class="input-title">
                <b v-html="showCount(index)"></b>
                <b v-html="content[index].length"></b>
              </span>
            </div> -->
          </div>
        </div>
      </div>
      <h5 class="title-text" style="color: rgb(67, 160, 107);">
        Preview:
      </h5>
      <div class="row">
        <div class="col-md-6" style="padding-right:0">
          <table
            id="testresult_answer"
            class="table table-condensed fixed-column"
            style="text-align: center;"
          >
            <thead>
              <tr>
                <td class="green-td"><b>#</b></td>
                <td
                  class="green-td"
                  v-for="(section, index) in content"
                  :key="index"
                  v-show="index != 'essay'"
                >
                  <b v-html="topic_array[index]"></b>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(answer, index) in content[max_topic]"
                :key="index"
                v-show="index <= 39"
              >
                <td class="green-td">
                  <b>{{ index + 1 }}</b>
                </td>
                <td
                  v-for="(title, index1) in content"
                  :key="index1"
                  v-show="index1 != 'essay'"
                >
                  <input
                    v-if="title[index] !== undefined"
                    class="form-control"
                    :value="title[index].answers"
                    :disabled="true"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-md-6" style="padding-left:0">
          <table
            id="testresult_answer"
            class="table table-condensed fixed-column"
            style="text-align: center;"
          >
            <thead>
              <tr>
                <td class="green-td"><b>#</b></td>
                <td
                  class="green-td"
                  v-for="(section, index) in content"
                  :key="index"
                  v-show="index != 'essay'"
                >
                  <b v-html="topic_array[index]"></b>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(answer, index) in content[max_topic]"
                v-show="index > 39"
                :key="index"
              >
                <td class="green-td">
                  <b>{{ index + 1 }}</b>
                </td>
                <td
                  style="background-color: rgb(255, 255, 255);"
                  v-for="(title, index1) in content"
                  :key="index1"
                  v-show="index1 != 'essay'"
                >
                  <input
                    v-if="title[index] !== undefined"
                    class="form-control"
                    name=""
                    :value="title[index].answers"
                    placeholder=""
                    :disabled="true"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- SCORING TABLE &nbsp;&nbsp; -->
    <div class="">
      <hr />
      <h4 style="font-size:26px;color:#43a06b;">
        SCORING TABLE
      </h4>
    </div>
    <!-- Raw Score Conversion Table 1: Section and Test Scores -->
    <div>
      <h4 style="font-size:26px;color:#43a06b;">
        Raw Score Conversion Table: Section and Test Scores
      </h4>
      <div style="margin-bottom:20px" v-show="!cannotEdit">
        <el-alert type="warning" :closable="false" show-icon>
          <ul style="margin-bottom:0">
            <li>English Test Score: (<b>0 ~ 36</b>) ;</li>
            <li>Math Test Score: (<b>0 ~ 36</b>).</li>
            <li>Reading Test Score: (<b>0 ~ 36</b>) ;</li>
            <li>Science Test Score: (<b>0 ~ 36</b>) ;</li>
          </ul>
        </el-alert>
      </div>
      <div class="row">
        <div class="col-sm-6" style="padding-right:0">
          <table
            id="testresult_answer"
            class="table table-condensed fixed-column"
            style="text-align: center;"
          >
            <thead>
              <tr>
                <td class="green-td"><b>#</b></td>
                <td
                  class="green-td"
                  v-for="(title, index) in score_table"
                  :key="index"
                >
                  <b v-html="topic_array[index] + ' Test Score'"></b>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(answer, index) in score_table[max_score_table]"
                v-show="index <= 39"
                :key="index"
              >
                <td class="green-td">
                  <b>{{ index }}</b>
                </td>
                <td
                  style="background-color: rgb(255, 255, 255);"
                  v-for="(title, index1) in score_table"
                  :key="index1"
                >
                  <input
                    class="form-control"
                    v-show="title[index] !== undefined"
                    name=""
                    v-model.number="title[index]"
                    :disabled="cannotEdit"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-sm-6" style="padding-left:0">
          <table
            id="testresult_answer"
            class="table table-condensed fixed-column"
            style="text-align: center;"
          >
            <thead>
              <tr>
                <td class="green-td"><b>#</b></td>
                <td
                  class="green-td"
                  v-for="(title, index) in score_table"
                  :key="index"
                >
                  <b v-html="topic_array[index] + ' Test Score'"></b>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(answer, index) in score_table[max_score_table]"
                v-show="index > 39"
                :key="index"
              >
                <td class="green-td">
                  <b>{{ index }}</b>
                </td>
                <td
                  style="background-color: rgb(255, 255, 255);"
                  v-for="(title, index1) in score_table"
                  :key="index1"
                >
                  <input
                    class="form-control"
                    v-show="title[index] !== undefined"
                    name=""
                    v-model.number="title[index]"
                    placeholder=""
                    :disabled="cannotEdit"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import _ from "lodash";
import actAPI from "@/apis/act.js";
import testsAPI from "@/apis/tests.js";
import JwtService from "@/common/jwt.service";
import TestForm from "@/components/tests/TestForm.vue";
import SectionTable from "@/components/tests/SectionTable.vue";

export default {
  metaInfo() {
    return {
      title: "Test Answers - Ivy-Way Academy"
    };
  },
  components: { TestForm, SectionTable },

  mixins: [],

  props: [],
  data() {
    return {
      token: { Authorization: `Bearer ${JwtService.getToken()}` },
      act_info: {
        full_name: "",
        id: 0,
        title: "",
        url: {
          test: ""
        }
      },
      content: {
        english: [],
        math: [],
        reading: [],
        science: []
      },
      test: "barronsat0",
      max_topic: "english",
      max_score_table: "english",
      score_table: {
        english: [],
        math: [],
        reading: [],
        science: []
      },
      cannotEdit: true,
      topic_array: {
        english: "English",
        math: "Math",
        reading: "Reading",
        science: "Science"
      },
      load: false,
      sections: ["Reading", "Writing", "Math", "MathC"],
      test_answers_detail: {
        english: "",
        math: "",
        reading: "",
        science: ""
      },
      createNew: 0,
      fileLoading: false
    };
  },
  computed: {
    exam_id() {
      let exam_id = this.$route.query.exam_id;
      return exam_id;
    },
    canNotEdit() {
      let canNotEdit = true;
      if (this.$route.query.can_edit == 1 || this.$route.query.create == 1) {
        canNotEdit = false;
      } else {
        canNotEdit = true;
      }
      return canNotEdit;
    },
    postUrl() {
      return process.env.VUE_APP_BACKEND_URL + "document";
    }
  },
  watch: {},

  mounted() {
    if (this.$route.query.create) {
      this.createNew = this.$route.query.create;
    } else {
      this.getAnswers();
    }
    this.cannotEdit = this.canNotEdit;
  },

  methods: {
    setAllAnswer() {
      this.setAnswer("reading");
      this.setAnswer("english");
      this.setAnswer("math");
      this.setAnswer("science");
      this.setMax();
    },
    setMax() {
      let gradeOne_new = [];
      const content = this.content;
      for (let i in content) {
        let obj = {
          key: i,
          data: content[i],
          length: content[i].length
        };
        gradeOne_new.push(obj);
      }
      let max = gradeOne_new.sort(function(a, b) {
        return b.length - a.length;
      })[0];
      this.max_topic = max.key;

      let scoreTable_new = [];
      const scoreTable = this.score_table;
      for (let i in scoreTable) {
        let obj = {
          key: i,
          data: scoreTable[i],
          length: scoreTable[i].length
        };
        scoreTable_new.push(obj);
      }
      let max_score = scoreTable_new.sort(function(a, b) {
        return b.length - a.length;
      })[0];
      this.max_score_table = max_score.key;
    },
    async getAnswers() {
      await Promise.all([
        actAPI.getAnswers(this.exam_id),
        testsAPI.getTest("ACT", this.exam_id),
        actAPI.getConversion(this.exam_id)
      ]).then(values => {
        const res = values[0];
        const info = values[1];
        const conversion = values[2];
        this.content = res;
        this.test_answers_detail = this.getTest_answers_detail();
        this.act_info = info;
        this.score_table.english = conversion.act_score_conversion.english;
        this.score_table.math = conversion.act_score_conversion.math;
        this.score_table.reading = conversion.act_score_conversion.reading;
        this.score_table.science = conversion.act_score_conversion.science;
        this.setMax();
      });
    },
    getTest_answers_detail() {
      let content = this.content;
      let new_obj = {};
      for (let key in content) {
        new_obj[key] = this.getChoices(content[key]);
      }
      return new_obj;
    },
    async updateScore() {
      let vm = this;
      vm.load = true;
      await testsAPI.updateAllACTStudents(this.exam_id);
      this.$message({
        message: "Success!",
        type: "success"
      });
    },
    async updateAnswers() {
      let contentIndex = 1;
      for (let i in this.content) {
        let section = this.content[i];
        let newSection = _.filter(section, function(answser) {
          return answser.answers !== "";
        });
        newSection.forEach(answer => {
          answer.order = contentIndex;
          contentIndex++;
        });
        contentIndex = 1;
        this.content[i] = newSection;
      }
      await Promise.all([
        testsAPI.updateTestInfo("ACT", this.exam_id, {
          title: this.act_info.title,
          full_name: this.act_info.full_name,
          url: {
            test: this.act_info.url !== null ? this.act_info.url.test : ""
          },
          test_book_ids: this.act_info.test_book_ids
        }),
        actAPI.updateAnswers(this.exam_id, {
          answers: this.content
        }),
        actAPI.updateConversion(this.exam_id, {
          score_conversion: this.score_table
        })
      ]);
      this.reloadAnswer(this.act_info.id);
      this.cannotEdit = true;
    },
    getChoices(arr) {
      let ans = "";
      arr.forEach(val => {
        let item = val.answers;
        let value = "";
        if (item !== undefined) {
          if (item.length > 2) {
            value = `(${item})`;
          } else {
            if (isNaN(item)) {
              value = item;
            } else {
              value = `(${item})`;
            }
          }
        }
        ans += value;
      });
      return ans;
    },
    setAnswer: function(string) {
      let answer = this.test_answers_detail[string];
      let newArr = answer.split("");
      let i = 0;
      let y = 0;
      let index = 0;
      if (this.createNew === "1") {
        this.content[string] = [];
        this.score_table[string] = [];
      } else {
        this.content[string].forEach(res => {
          res.answers = "";
        });
        while (i < newArr.length) {
          if (this.content[string][index] == undefined) {
            this.content[string].push({
              answers: "",
              name: string,
              order: 0,
              act_question_id: 0
            });
          }
          if (newArr[i] == "(") {
            y = answer.indexOf(")", i);
            let answer_new = answer.substring(i + 1, y);
            this.content[string][index].answers = answer_new;
            i = y;
          } else {
            y = i;
            this.content[string][index].answers = newArr[i];
          }
          i++;
          index++;
        }
        let newSection = _.filter(this.content[string], function(answser) {
          return (
            answser.act_question_id > 0 ||
            (answser.answers !== "" && answser.act_question_id === 0)
          );
        });
        this.content[string] = newSection;
      }
      while (i < newArr.length) {
        this.content[string].push({
          answers: "",
          name: string,
          order: 0,
          act_question_id: 0
        });
        if (newArr[i] == "(") {
          y = answer.indexOf(")", i);
          let answer_new = answer.substring(i + 1, y);
          this.content[string][index]["answers"] = answer_new;
          i = y;
        } else {
          y = i;
          this.content[string][index]["answers"] = newArr[i];
        }
        // this.score_table[string].push(0);
        i++;
        index++;
      }
      let j = 0;
      let arr = [0];
      while (j < this.content[string].length) {
        if (this.content[string][j].answers !== "") {
          if (this.score_table[string][j] === undefined) {
            arr.push(0);
          } else {
            arr.push(this.score_table[string][j]);
          }
        }
        j++;
      }
      this.score_table[string] = arr;
    },
    async createAnswers() {
      const res = await actAPI.createAnswers({
        info: this.act_info,
        answers: this.content,
        score_conversion: this.score_table
      });
      this.reloadAnswer(res.act_id);
    },
    reloadAnswer(act_id) {
      this.$router.replace({
        name: "ActAnswers",
        query: {
          exam_id: act_id
        }
      });
    },
    handleAvatarSuccess(res) {
      this.act_info.url = { test: res.document.url };
      this.fileLoading = false;
    },
    beforeAvatarUpload() {
      let canUpload = true;
      this.fileLoading = true;
      return canUpload;
    }
  }
};
</script>

<style scoped>
.answers {
  padding: 20px 0;
}
.action-btns {
  z-index: 9999999;
  position: fixed;
  top: 120px;
  right: 0px;
}
hr {
  height: 4px;
  background-color: #43a06b;
}
.action-btns {
  margin-bottom: 15px;
}
h4.title-text {
  color: #43a06b;
  margin-right: 10px;
  line-height: 44px;
  height: 44px;
  margin-bottom: 0;
}
.title {
  display: flex;
  margin-bottom: 10px;
}
.title .title-div {
  margin-left: 20px;
}
.title-input.form-control {
  text-align: left;
}
.input-line {
  margin-bottom: 20px;
}
.input-title {
  line-height: 44px;
}

ol.breadcrumb {
  margin-bottom: 0px;
}
.panel-heading {
  padding: 0 15px;
}
.panel-title {
  font-size: 26px;
  color: #43a06b;
}
.panel-body {
  padding: 15px;
}
select#test_id {
  height: 25px;
  margin-bottom: 0px;
}
.test_type {
  background: #ddd;
  padding-left: 20px;
  cursor: pointer;
}
.test_type:hover {
  background: #cacaca;
}
.sign {
  min-width: 25px;
}
.test_list {
  background: #fafafa;
  padding-left: 30px;
  display: none;
}
.panel {
  margin-bottom: 50px;
}

.green-td {
  width: auto;
}

#testresult_answer,
#testresult_table {
  text-align: center;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -khtml-border-radius: 2px;
  -webkit-border-radius: 2px;
  border: #43a06b 1px solid;
}

#testresult_answer td,
#testresult_table td {
  height: 20px;
  padding: 2px 5px;
  text-align: center;
  vertical-align: middle;
  border: #43a06b 1px solid;
  border-right: 3px solid #4fb81e;
}

#testresult_answer thead td,
#testresult_table thead td {
  padding: 10px 0;
}
.form-control {
  text-align: center;
  font-weight: 700;
  /* border: 1px solid #fff; */
  box-shadow: none;
  font-size: 20px;
}
.form-control:hover {
  border: 1px solid #ccc;
}
.test_id .form-control {
  border: 1px solid #ccc;
}
#add_button {
  border: 1px solid #fff;
  background: #fe4902;
  display: block;
  width: 50px;
  height: 50px;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  position: fixed;
  bottom: 70px;
  right: 17px;
  border-radius: 50%;
  color: #fff;
  font-size: 30px;
  cursor: pointer;
  z-index: 1;
}
.btn_active {
  background-color: white;
  color: #43a06b;
}
h3 .form-control {
  display: inline-block;
  width: 50%;
  text-align: left;
}
.list_btn {
  width: 34px;
  background-color: white;
  color: black;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  padding: 5px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.list_btn.active {
  background-color: #43a06b;
  color: white;
}
.table .form-control {
  /* height: 25px; */
  padding: 3px 12px;
}
h2 .testIdSave {
  display: inline-block;
  cursor: pointer;
  color: #43a06b;
  /*font-size: 14px;*/
  line-height: 34px;
  height: 34px;
  cursor: pointer;
}
.test_input .form-control {
  border: 1px solid #ccc;
  text-align: left;
}
.comments {
  text-align: left;
  border: 1px solid #ccc;
  font-size: 14px;
  font-weight: normal;
}
.load {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 8001;
  background: black;
  filter: alpha(opacity=50);
  -moz-opacity: 0.5;
  -khtml-opacity: 0.5;
  opacity: 0.5;
}
.fullscreen-hint {
  font-size: 24px;
  color: white;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 8002;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: #fff;
  border-color: #fff;
  opacity: 1;
}
</style>
